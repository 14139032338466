import styled from '@emotion/styled';
import logo from '../assets/logo.svg';
export default styled.div`
  width: 160px;
  height: 48px;
  background: url(${logo}) center no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
`;
