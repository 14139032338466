import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 0px;
  display: grid;
  grid-template-columns: 280px calc(100% - 320px);
  grid-auto-flow: row;
  grid-gap: 30px;
  @media (max-width: 780px) {
    padding: 24px;
    grid-template-columns: 100%;
  }
`;

export const Main = styled.main`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
  height: 100%;
  @media (max-width: 1390px) {
    margin: auto 20px;
  }
`;

export const Children = styled.div`
  width: 100%;
  max-width: 680px;
  padding-top: 20px;
  margin: 10px auto;
  @media (max-width: 1390px) {
    padding-right: 0;
    padding-top: 0;
    margin: 0px 0px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
`;
